import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
  ZubeModal,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { UserForm } from "components/forms";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { isEmpty, isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { userResources } from "services/resources/firebase/user";
import { authResources } from "services/resources/firebase/auth";
import {
  IUser,
  UserPayloadType,
  UserTypeEnum,
} from "services/resources/firebase/user/types.d";
import { zubeColors } from "styles/colors";
import { FirebaseError } from "firebase/app";
import { partnerResources } from "services/resources/firebase/partner";
import { IPartner } from "services/resources/firebase/partner/types.d";

export const Users: React.FC = (): JSX.Element => {
  const {
    id: userId,
    organization,
    token,
    type,
    email: loggedUserEmail,
  } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [formType, setFormType] = useState<string>("");
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser>({} as IUser);
  const [partners, setPartners] = useState<IPartner[]>([]);

  const handleOnUsersGet = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      if (type === UserTypeEnum.PARTNER) {
        const partnerAdmins = await userResources.getPartnerAdmins(
          organization as string,
          token as string
        );
        if (!isEmpty(partnerAdmins)) {
          // filter out deleted users (i.e. info swiped)
          setUsers(
            partnerAdmins.filter(
              (user) => user.email && user.email !== loggedUserEmail
            )
          );
        }
      } else {
        const { data } = await userResources.getAdmins(token as string);
        if (!isNil(data)) {
          // filter out deleted users (i.e. info swiped)
          setUsers(
            data.filter((user) => user.email && user.email !== loggedUserEmail)
          );
        }
      }
    } catch (e) {
      console.error("Error-handleOnUsersGet: ", e);
    }
    setLoadingModalVisible(false);
  };

  /**
   * Function to handle the creation and edition of admins.
   * @param userPayload
   */
  const handleOnSubmitUser = async (
    payload: UserPayloadType & { password: string }
  ): Promise<void> => {
    onClose();
    setLoadingModalVisible(true);
    const { email, fullName, type, organization: payloadOrg } = payload;
    const userPayload: Partial<UserPayloadType> = {
      email,
      fullName,
      type,
      organization: type === UserTypeEnum.PARTNER ? organization : payloadOrg,
    };
    try {
      if (!isEmpty(selectedUser)) {
        await userResources.patchAdminUser(
          userPayload,
          selectedUser.id,
          token as string
        );
      } else {
        await authResources.signUpWithEmailAndPassword({
          email: payload.email,
          password: payload.password,
        });
        await userResources.createAdmin(userPayload, token as string);
      }
    } catch (e) {
      console.error("Error-handleOnSubmitUser: ", e);
      if (e instanceof FirebaseError) {
        window.alert("Este correo electrónico ya está en uso.");
      } else window.alert("Ocurrió un error al crear/editar el administrador.");
    } finally {
      setFormType("");
      setSelectedUser({} as IUser);
      handleOnUsersGet();
      setLoadingModalVisible(false);
    }
  };

  const handleOnDeleteUser = async (
    targetId: string,
    targetEmail: string
  ): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      // swipe user data from the database
      await userResources.delete(targetId, token as string);
      // remove email from authentication
      await authResources.deleteAccount(targetEmail, token as string);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error-handleOnDeleteUser:", e);
      window.alert("Ocurrió un error al eliminar el administrador.");
    } finally {
      handleOnUsersGet();
      setLoadingModalVisible(false);
    }
  };

  const handleOnRetrievePartners = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await partnerResources.getAll(token as string);
      if (!isNil(data)) setPartners(data);
    } catch (e) {
      console.error("handleOnRetrievePartners: ", e);
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const getRoleLabel = (role: UserTypeEnum) => {
    switch (role) {
      case UserTypeEnum.PARTNER:
        return "Partner";
      case UserTypeEnum.ADMIN:
        return "Administrador";
      // support older admins with isAdmin flag
      default:
        return "Administrador";
    }
  };

  const columns: ITypedListTableColumn<IUser>[] = [
    {
      accessorKey: "img",
      header: "Imagen",
      cell: ({ row }) => (
        <Box maxH="70px">
          {!isNil(row.original.img) ? (
            <Image src={row.original.img} h="70px" />
          ) : (
            <Text>Sin imagen</Text>
          )}
        </Box>
      ),
    },
    {
      accessorKey: "fullName",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.fullName}</Text>,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => <Text>{row.original.email}</Text>,
    },
    {
      accessorKey: "type",
      header: "Rol",
      cell: ({ row }) => <Text>{getRoleLabel(row.original.type)}</Text>,
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            rounded="full"
            fontSize={12}
            onClick={() => {
              setSelectedUser(row.original);
              setFormType("edit");
              onOpen();
            }}
          >
            Editar
          </Button>
          <Button
            color="white"
            bg="red"
            rounded="full"
            fontSize={12}
            onClick={() =>
              row.original.id === userId
                ? window.alert(
                    "Para eliminarte a ti mismo, ve a tu sección de Editar Perfil."
                  )
                : handleOnDeleteUser(row.original.id, row.original.email)
            }
          >
            Eliminar
          </Button>
        </VStack>
      ),
    },
  ];

  useEffect(() => {
    handleOnUsersGet();
    handleOnRetrievePartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Usuarios"
      loadingModalVisible={loadingModalVisible}
    >
      <ZubeModal
        isOpen={isOpen}
        onClose={() => {
          setSelectedUser({} as IUser);
          onClose();
        }}
        title={formType === "create" ? "Crear Admin" : "Editar Admin"}
      >
        <UserForm
          handleOnSubmit={handleOnSubmitUser}
          isPartner={type === UserTypeEnum.PARTNER}
          onScreenChange={() => {
            setSelectedUser({} as IUser);
            onClose();
          }}
          previousValues={
            !isEmpty(selectedUser)
              ? { ...selectedUser, password: "" }
              : ({} as IUser & { password: string })
          }
          partners={partners}
        />
      </ZubeModal>
      <HStack justifyContent="flex-end" w="95%">
        <Button
          bg={zubeColors.zubeOrange.light}
          borderRadius="full"
          color="white"
          mt={5}
          onClick={() => {
            setFormType("create");
            onOpen();
          }}
        >
          Nuevo usuario
        </Button>
      </HStack>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {users.map((user, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={user.fullName}
                  rightElement={<Image src={user.img} h="30px" />}
                >
                  <VStack w="100%">
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="35%">
                        Email
                      </Text>
                      <Text>{user.email}</Text>
                    </HStack>
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="35%">
                        Rol
                      </Text>
                      <Text>{getRoleLabel(user.type)}</Text>
                    </HStack>
                    <Divider />
                    <HStack>
                      <Button
                        color="white"
                        bg={zubeColors.zubeGreen}
                        rounded="full"
                        fontSize={12}
                        onClick={() => {
                          setSelectedUser(user);
                          setFormType("edit");
                          onOpen();
                        }}
                      >
                        Editar
                      </Button>
                      <Button
                        color="white"
                        bg="red"
                        rounded="full"
                        fontSize={12}
                        onClick={() =>
                          user.id === userId
                            ? window.alert(
                                "Para eliminarte a ti mismo, ve a tu sección de Editar Perfil."
                              )
                            : handleOnDeleteUser(user.id, user.email)
                        }
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="90%" alignSelf="center">
          <Center bg="white" w="90%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={users}
                columns={columns as ColumnDef<object>[]}
                w="90%"
                h="90%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
