import {
  Button,
  HStack,
  Select,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { isEmpty } from "lodash/fp";
import { zubeColors } from "styles/colors";
import { InputField } from "components/inputs";
import {
  IUser,
  UserPayloadType,
  UserTypeEnum,
} from "services/resources/firebase/user/types.d";
import {
  INITIAL_VALUES,
  UserFormValidationSchema,
} from "./helpers/form-helpers";
import { IPartner } from "services/resources/firebase/partner/types.d";

interface IUserFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (
    payload: UserPayloadType & { password: string }
  ) => Promise<void>;
  /**
   * Flag to indicate if user type can be selected.
   */
  isPartner: boolean;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Initial values for the form.
   */
  previousValues?: IUser & { password: string };
  partners: IPartner[];
}

export const UserForm: React.FC<IUserFormProps> = (props) => {
  const {
    handleOnSubmit,
    isPartner,
    onScreenChange,
    previousValues,
    partners,
    ...rest
  } = props;

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={UserFormValidationSchema(
        isEmpty(previousValues) ? true : false
      )}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
      }) => (
        <VStack {...rest}>
          <InputField
            error={errors.fullName}
            label="Nombre"
            onChangeText={handleChange("fullName")}
            type="text"
            value={values.fullName}
          />
          <InputField
            error={errors.email}
            isDisabled={!isEmpty(previousValues)}
            label="Email"
            onChangeText={handleChange("email")}
            type="email"
            value={values.email}
          />
          <InputField
            error={errors.password}
            isDisabled={!isEmpty(previousValues)}
            label="Contraseña"
            onChangeText={handleChange("password")}
            type="password"
            value={values.password}
          />
          {!isPartner ? (
            <>
              <Text alignSelf="flex-start" fontSize={14} fontWeight="bold">
                Tipo de Administrador
              </Text>
              <Select
                mb={4}
                onChange={(e) => {
                  setFieldValue("type", e.target.selectedOptions[0].value);
                }}
                placeholder="Selecciona un tipo de administrador"
                value={values.type}
              >
                <option value="partner">Partner</option>
                <option value="admin">Global</option>
              </Select>
              <Text alignSelf="flex-start" fontSize={14} fontWeight="bold">
                Organización
              </Text>
              <Select
                mb={4}
                onChange={(e) => {
                  setFieldValue(
                    "organization",
                    e.target.selectedOptions[0].value
                  );
                }}
                isDisabled={values.type === UserTypeEnum.ADMIN}
                placeholder="Selecciona una organización"
                value={values.organization}
              >
                {partners.map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.name}
                  </option>
                ))}
              </Select>
            </>
          ) : null}
          <HStack w="90%" mb={5}>
            <Button
              bg="white"
              borderRadius="full"
              borderColor={zubeColors.zubeOrange.light}
              borderWidth={1}
              onClick={onScreenChange}
              w="100%"
              color={zubeColors.zubeOrange.light}
            >
              Cancelar
            </Button>
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={
                !isEmpty(errors) ||
                (!isPartner &&
                  values.type === UserTypeEnum.PARTNER &&
                  isEmpty(values.organization))
              }
              justifyContent="center"
              onClick={() => {
                if (isValid) handleSubmit();
              }}
              w="100%"
              color="white"
            >
              {!isEmpty(previousValues) ? "Actualizar" : "Crear"}
            </Button>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
};

UserForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
