import { StackProps } from "@chakra-ui/react";
import { DashboardPanel, ProgressBadge } from "components/elements";

interface IJourneysStatsProps extends StackProps {
  cancelled: number;
  cancelledBecauseOfLongWait: number;
  finished: number;
  allJourneysCount: number;
}

export const JourneysStats: React.FC<IJourneysStatsProps> = (
  props
): JSX.Element => {
  const {
    cancelled,
    cancelledBecauseOfLongWait,
    finished,
    allJourneysCount,
    ...rest
  } = props;

  return (
    <DashboardPanel alignItems="flex-start" label="Viajes" {...rest}>
      <ProgressBadge
        total={allJourneysCount}
        sum={cancelled}
        sumLabel="Viajes cancelados"
        w="100%"
      />
      <ProgressBadge
        total={allJourneysCount}
        sum={cancelledBecauseOfLongWait}
        sumLabel="Viajes cancelados por tiempo"
        w="100%"
      />
      <ProgressBadge
        total={allJourneysCount}
        sum={finished}
        sumLabel="Viajes terminados"
        w="100%"
      />
    </DashboardPanel>
  );
};
