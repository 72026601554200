import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { format } from "date-fns";
import { isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { zubeColors } from "styles/colors";
import {
  IDriverWithRequestAndUnit,
  IDriverWithRequestAndUnitsPayload,
} from "services/resources-recipes/types.d";
import { UnitData } from "./components/UnitData";
import {
  getDriversWithRequestsAndUnits,
  getDriverWithRequestAndUnitByDriverId,
} from "services/resources-recipes/user-recipes";
import { getVerificationStatus } from "helpers/data-helpers/string-helpers";
import { DriversUI } from "./components/DriversUI";
import { useNavigate, useParams } from "react-router-dom";
import { DriverDetailUI } from "./components/DriverDetailUI";
import { unitResources } from "services/resources/firebase/unit";
import { IUnit } from "services/resources/firebase/unit/types.d";
import { handleDriverAndRequestImagesUpload } from "services/resources-recipes/storage";
import { userResources } from "services/resources/firebase/user";
import { driverRequestResources } from "services/resources/firebase/driver-request/driver-request";
import { authResources } from "services/resources/firebase/auth";
import { DEFAULT_SECONDARY_DRIVER_REQUEST_DATA } from "helpers/constants/data-constants";
import { UserTypeEnum } from "services/resources/firebase/user/types.d";
import { partnerResources } from "services/resources/firebase/partner";
import { IPartner } from "services/resources/firebase/partner/types.d";
import { isEmpty } from "lodash";
import { DriverFilterButton } from "./components/DriverFilterButton";
import { VerificationStatusEnum } from "services/resources/firebase/driver-request/types.d";

export const Drivers: React.FC = (): JSX.Element => {
  const {
    token,
    type,
    organization,
    isAdmin: isUserAdminOnDb,
  } = useSelector(getUser);
  const { driverId } = useParams();
  const navigate = useNavigate();

  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [driversWithRequestsAndUnits, setDriversWithRequestsAndUnits] =
    useState<IDriverWithRequestAndUnit[]>([]);
  const [openedLicense, setOpenedLicense] = useState<undefined | string>(
    undefined
  );
  const [
    selectedDriverWithRequestAndUnits,
    setSelectedDriverWithRequestAndUnits,
  ] = useState<IDriverWithRequestAndUnit | undefined>(undefined);
  const [availableUnits, setAvailableUnits] = useState<IUnit[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [selectedDriverStatusTypeFilter, setSelectedDriverStatusTypeFilter] =
    useState<VerificationStatusEnum | "ALL">("ALL");

  const handleOnCreateStart = () => {
    setSelectedDriverWithRequestAndUnits(undefined);
    setIsCreating(true);
  };

  const isPartner = type === UserTypeEnum.PARTNER;
  const isAdmin = type === UserTypeEnum.ADMIN || isUserAdminOnDb;

  const retrieveDriversWithRequestsAndUnits = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const retrievedDriversWithRequestsAndUnits =
        await getDriversWithRequestsAndUnits(
          token as string,
          isPartner ? organization : undefined
        );
      setDriversWithRequestsAndUnits(
        retrievedDriversWithRequestsAndUnits.sort(
          (a, b) => b.driver.updated._seconds - a.driver.updated._seconds
        )
      );
    } catch (e) {
      console.error("Error-retrieveDriversUnits: ", e);
      window.alert("Ocurrió un error inesperado.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const fetchExistingDriverWithRequestAndUnit = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const fetchedDriverWithRequestAndUnit =
        await getDriverWithRequestAndUnitByDriverId(
          driverId as string,
          token as string
        );
      if (!isNil(fetchedDriverWithRequestAndUnit)) {
        setSelectedDriverWithRequestAndUnits(fetchedDriverWithRequestAndUnit);
      }
    } catch (e) {
      console.error(
        "fetchExistingDriverWithRequestAndUnit: there was an issue fetching data"
      );
      window.alert("Ocurrió un error al leer los datos.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const fetchUnits = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = isAdmin
        ? await unitResources.getAll(token as string)
        : await unitResources.getByPartnerId(
            organization as string,
            token as string
          );
      if (!isNil(data)) {
        setAvailableUnits(data);
      }
    } catch (e) {
      console.error("fetchUnits: there was an issue fetching data");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnEditDriverRedirect = (driverId: string): void => {
    navigate(`${driverId}`);
  };

  const handleOnCreateDriver = async (
    payload: IDriverWithRequestAndUnitsPayload
  ): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      // Create user in Firebase authentication
      await authResources.signUpWithEmailAndPassword({
        email: payload.driver.email,
        password: payload.driver.password,
      });
      // Create user and requests images in Firebase storage
      const {
        driver: { password, ...passwordlessDriverPayload },
        // Exclude fields that will not be used in the payload
        request: { id: requestId, driverId, ...validRequestPayload },
      } = await handleDriverAndRequestImagesUpload(
        payload,
        selectedDriverWithRequestAndUnits as IDriverWithRequestAndUnit,
        false
      );
      // Create user in database
      await userResources.create(
        {
          ...passwordlessDriverPayload,
          // Either we use currently signed in user organization or the one
          // on the payload, depending on the user being partner or not
          organization: isPartner ? organization : payload.driver.organization,
          // Either we `PUBLIC_DRIVER` (as is the one for partner drivers always)
          // or the one on the payload, depending on the user being partner or not
          type: isPartner ? UserTypeEnum.PUBLIC_DRIVER : payload.driver.type,
        },
        token as string
      );
      // Get just-created user
      const { data: createdDriverData } = await userResources.getByEmail(
        payload.driver.email,
        token as string
      );
      // If the just-created user is valid, proceed
      if (!isNil(createdDriverData)) {
        // Create driver request in database
        await driverRequestResources.create(
          {
            driverId: createdDriverData.id,
            ...validRequestPayload,
            ...DEFAULT_SECONDARY_DRIVER_REQUEST_DATA,
          },
          token as string
        );
        // Update driver's units
        await Promise.allSettled(
          payload.units.map(async (unit) => {
            try {
              return await unitResources.patchUnitDriver(
                unit.id,
                createdDriverData.id,
                token as string
              );
            } catch (e) {
              console.error(
                "handleOnCreateDriver: there was an issue updating the unit.",
                JSON.stringify(e)
              );
            }
          })
        );
      }
    } catch (e) {
      console.error(
        "handleOnCreateDriver: there was an issue signing up this driver"
      );
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnEditDriver = async (
    payload: IDriverWithRequestAndUnitsPayload
  ): Promise<void> => {
    setLoadingModalVisible(true);
    // Try to update the units' driver
    try {
      // If the payload has unit items
      if (payload.units.length > 0) {
        await Promise.allSettled(
          payload.units.map(async (unit) => {
            try {
              return await unitResources.patchUnitDriver(
                unit.id,
                payload.request.driverId,
                token as string
              );
            } catch (e) {
              console.error(
                "handleOnEditDriver: there was an issue updating the unit.",
                JSON.stringify(e)
              );
            }
          })
        );
      } else {
        // In the other case, use the previous values
        const previousUnits = selectedDriverWithRequestAndUnits?.units;
        if (!isNil(previousUnits) && previousUnits.length > 0) {
          await Promise.allSettled(
            previousUnits.map(async (unit) => {
              try {
                return await unitResources.clearUnitDriver(
                  unit.id,
                  token as string
                );
              } catch (e) {
                console.error(
                  "handleOnEditDriver: there was an issue clearing the unit driver.",
                  JSON.stringify(e)
                );
              }
            })
          );
        }
      }
    } catch (e) {
      console.error("handleOnEditDriver: there was an issue updating driver");
    }
    // Update driver's media
    try {
      const {
        driver: { password, ...passwordlessDriverPayload },
        request,
      } = await handleDriverAndRequestImagesUpload(
        payload,
        selectedDriverWithRequestAndUnits as IDriverWithRequestAndUnit,
        false
      );
      await userResources.patchDriverUser(
        request.driverId,
        passwordlessDriverPayload,
        token as string
      );
      await driverRequestResources.patchDriverLicense(
        request.driverLicense,
        request.id,
        token as string
      );
      await driverRequestResources.patchIdCard(
        request.idCard,
        request.id,
        token as string
      );
      // Update password if passed in the payload
      if (!isNil(password)) {
        const { data } = await authResources.getUidFromEmail(
          passwordlessDriverPayload.email,
          token as string
        );
        if (!isNil(data)) {
          await authResources.updatePassword(
            data.uid,
            password,
            token as string
          );
        }
      }
      // Update `mobilePhoneNumber` in auth if different from
      // existing one
      if (
        passwordlessDriverPayload.mobilePhoneNumber !==
        selectedDriverWithRequestAndUnits?.driver.mobilePhoneNumber
      ) {
        const { data } = await authResources.getUidFromEmail(
          passwordlessDriverPayload.email,
          token as string
        );
        if (!isNil(data)) {
          await authResources.linkPhoneNumberToUser(
            data.uid,
            passwordlessDriverPayload.mobilePhoneNumber,
            token as string
          );
        }
      }
    } catch (e) {
      console.error(
        "handleOnEditDriver: there was an issue updating the driver and/or the request"
      );
      window.alert("Ocurrió un error al editar al conductor.");
    } finally {
      setLoadingModalVisible(false);
      fetchExistingDriverWithRequestAndUnit();
    }
  };

  const handleOnFetchPartners = async () => {
    setLoadingModalVisible(true);
    try {
      const { data } = await partnerResources.getAll(token as string);
      if (!isNil(data)) {
        setPartners(data);
      }
    } catch (e) {
      console.error("handleOnFetchPartners: there was issue fetching partners");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const handleOnSelectDriverStatusTypeFilter = (
    driverStatusType: VerificationStatusEnum | "ALL"
  ): void => {
    setSelectedDriverStatusTypeFilter(driverStatusType);
  };

  const columns: ITypedListTableColumn<IDriverWithRequestAndUnit>[] = [
    {
      accessorKey: "img",
      header: "Imagen",
      cell: ({ row }) => (
        <Box maxH="70px">
          {!isNil(row.original.driver.img) ? (
            <Image
              src={row.original.driver.img}
              w="100px"
              objectFit="contain"
            />
          ) : (
            <Text>Sin imagen</Text>
          )}
        </Box>
      ),
    },
    {
      accessorKey: "fullName",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.driver.fullName}</Text>,
    },
    {
      header: "Datos de contacto",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <HStack>
            <MdEmail />
            <Text>{row.original.driver.email}</Text>
          </HStack>
          <HStack>
            <FaPhoneAlt />
            <Text>{row.original.driver.mobilePhoneNumber}</Text>
          </HStack>
        </VStack>
      ),
    },
    {
      accessorKey: "status",
      header: "Estatus",
      cell: ({ row }) => (
        <Text color={getVerificationStatus(row.original.request.status).color}>
          {getVerificationStatus(row.original.request.status).label}
        </Text>
      ),
    },
    {
      accessorKey: "organization",
      header: "Organización",
      cell: ({ row }) => (
        <Text>
          {!isNil(row.original.driver.organization) &&
          !isEmpty(row.original.driver.organization)
            ? partners.find(
                (partner) => partner.id === row.original.driver.organization
              )?.name
            : "Conductor privado"}
        </Text>
      ),
    },
    {
      accessorKey: "unit",
      header: "Vehículo Que Conduce",
      cell: ({
        row: {
          original: { units },
        },
      }) => (
        <VStack alignItems="flex-start">
          {units.length > 0 ? (
            units.map((unit) => <UnitData unit={unit} key={unit.id} />)
          ) : (
            <Text>Sin unidades asignadas</Text>
          )}
        </VStack>
      ),
    },
    {
      accessorKey: "license",
      header: "Licencia",
      cell: ({ row }) => (
        <Button
          rounded="full"
          bg="white"
          borderWidth={1}
          borderColor="pink"
          color="pink"
          onClick={() => {
            setOpenedLicense(row.original.driver.id);
          }}
        >
          Ver licencia
        </Button>
      ),
    },
    {
      accessorKey: "created",
      header: "Fecha de registro",
      cell: ({ row }) => (
        <Text>
          {format(
            new Date(row.original.driver.created._seconds * 1000),
            "yyyy-MM-dd HH:mm:ss"
          )}
        </Text>
      ),
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            rounded="full"
            fontSize={12}
            onClick={() => handleOnEditDriverRedirect(row.original.driver.id)}
          >
            Editar
          </Button>
          <Button bg="red" color="white" fontSize={12} rounded="full">
            Eliminar
          </Button>
        </VStack>
      ),
    },
  ];

  const openedLicenseSources = driversWithRequestsAndUnits.find(
    (driverWithRequestAndUnits) =>
      driverWithRequestAndUnits.driver.id === openedLicense
  )?.request.driverLicense.document;

  const shouldShowNewButton =
    (isNil(driverId) || isNil(selectedDriverWithRequestAndUnits)) &&
    !isCreating;
  const shouldShowEditUI =
    !isNil(driverId) &&
    !isNil(selectedDriverWithRequestAndUnits) &&
    !isCreating;
  const shouldShowDriversUI =
    isNil(driverId) && isNil(selectedDriverWithRequestAndUnits) && !isCreating;

  const approvedDriversWithRequestAndUnits = driversWithRequestsAndUnits.filter(
    (driverWithRequestAndUnit) =>
      driverWithRequestAndUnit.request.status ===
      VerificationStatusEnum.COMPLETED
  );

  const pendingDriversWithRequestAndUnits = driversWithRequestsAndUnits.filter(
    (driverWithRequestAndUnit) =>
      driverWithRequestAndUnit.request.status === VerificationStatusEnum.PENDING
  );

  const rejectedDriversWithRequestAndUnits = driversWithRequestsAndUnits.filter(
    (driverWithRequestAndUnit) =>
      driverWithRequestAndUnit.request.status ===
      VerificationStatusEnum.REJECTED
  );

  const filteredDriversWithRequestAndUnits =
    (): IDriverWithRequestAndUnit[] => {
      switch (selectedDriverStatusTypeFilter) {
        case VerificationStatusEnum.COMPLETED:
          return approvedDriversWithRequestAndUnits;
        case VerificationStatusEnum.PENDING:
          return pendingDriversWithRequestAndUnits;
        case VerificationStatusEnum.REJECTED:
          return rejectedDriversWithRequestAndUnits;
        default:
          return driversWithRequestsAndUnits;
      }
    };

  useEffect(() => {
    if (!isNil(driverId)) {
      fetchExistingDriverWithRequestAndUnit();
    } else {
      retrieveDriversWithRequestsAndUnits();
      fetchUnits();
      if (isAdmin) {
        handleOnFetchPartners();
      }
    }
    return () => {
      setSelectedDriverWithRequestAndUnits(undefined);
      setIsCreating(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId]);

  return (
    <PageContainer
      pageHeader="Conductores"
      loadingModalVisible={loadingModalVisible}
    >
      {shouldShowDriversUI ? (
        <HStack bg="white" borderRadius={5} p={3}>
          <DriverFilterButton
            driverStatusType="ALL"
            label="Todos"
            onSelectDriverStatusType={handleOnSelectDriverStatusTypeFilter}
            isFocused={selectedDriverStatusTypeFilter === "ALL"}
            itemCount={driversWithRequestsAndUnits.length}
          />
          <Text color="gray.300">/</Text>
          <DriverFilterButton
            driverStatusType={VerificationStatusEnum.COMPLETED}
            label="Aceptados"
            onSelectDriverStatusType={handleOnSelectDriverStatusTypeFilter}
            isFocused={
              selectedDriverStatusTypeFilter ===
              VerificationStatusEnum.COMPLETED
            }
            itemCount={approvedDriversWithRequestAndUnits.length}
          />
          <Text color="gray.300">/</Text>
          <DriverFilterButton
            driverStatusType={VerificationStatusEnum.PENDING}
            label="Pendientes"
            onSelectDriverStatusType={handleOnSelectDriverStatusTypeFilter}
            isFocused={
              selectedDriverStatusTypeFilter === VerificationStatusEnum.PENDING
            }
            itemCount={pendingDriversWithRequestAndUnits.length}
          />
          <Text color="gray.300">/</Text>
          <DriverFilterButton
            driverStatusType={VerificationStatusEnum.REJECTED}
            label="Rechazados"
            onSelectDriverStatusType={handleOnSelectDriverStatusTypeFilter}
            isFocused={
              selectedDriverStatusTypeFilter === VerificationStatusEnum.REJECTED
            }
            itemCount={rejectedDriversWithRequestAndUnits.length}
          />
        </HStack>
      ) : null}
      {shouldShowNewButton ? (
        <HStack display="flex" w="100%" justifyContent="flex-end" p={5}>
          <Button
            color="white"
            bg={zubeColors.zubePurple.dark}
            rounded="3xl"
            mr={5}
            onClick={handleOnCreateStart}
          >
            Agregar nuevo
          </Button>
        </HStack>
      ) : null}
      {shouldShowEditUI ? (
        <DriverDetailUI
          availableUnits={availableUnits}
          handleOnSubmit={handleOnEditDriver}
          previousValues={selectedDriverWithRequestAndUnits}
          existingPartners={partners}
        />
      ) : null}
      {shouldShowDriversUI ? (
        <DriversUI
          columns={columns}
          setOpenedLicense={setOpenedLicense}
          openedLicense={openedLicense}
          openedLicenseSources={openedLicenseSources}
          driversWithRequestsAndUnits={filteredDriversWithRequestAndUnits()}
        />
      ) : null}
      {isCreating ? (
        <DriverDetailUI
          availableUnits={availableUnits}
          handleOnSubmit={handleOnCreateDriver}
          isCreating={isCreating}
          existingPartners={partners}
        />
      ) : null}
    </PageContainer>
  );
};
