import {
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
  ZubeModal,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { isEmpty, isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { couponResources } from "services/resources/firebase/coupon";
import {
  ICoupon,
  CouponPayloadType,
} from "services/resources/firebase/coupon/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { zubeColors } from "styles/colors";
import { CouponForm } from "components/forms";
import { format } from "date-fns";
import { CustomTimestamp } from "services/resources/firebase/types.d";

export const Coupons: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formType, setFormType] = useState<string>("");
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<ICoupon>({} as ICoupon);

  const handleOnRetrieveCoupons = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await couponResources.getAll(token as string);
      if (!isNil(data)) setCoupons(data);
    } catch (e) {
      console.error("Error-handleOnRetrieveCoupons: ", e);
    }
    setLoadingModalVisible(false);
  };

  const handleOnSubmitCoupon = async (
    couponPayload: CouponPayloadType,
    discountType: string
  ): Promise<void> => {
    const parsedPayload: CouponPayloadType = {
      ...couponPayload,
      beginDate: (couponPayload.beginDate as Date).toISOString(),
      endDate: (couponPayload.endDate as Date).toISOString(),
      discountInPesos:
        discountType === "mxn"
          ? Number(couponPayload.discountInPesos)
          : undefined,
      discountPercentage:
        discountType === "percentage"
          ? Number(couponPayload.discountPercentage)
          : undefined,
      redeems: {
        total: Number(couponPayload.redeems.total),
        used: Number(couponPayload.redeems.used),
      },
    };
    onClose();
    setLoadingModalVisible(true);
    try {
      !isEmpty(selectedCoupon)
        ? await couponResources.patch(
            parsedPayload,
            selectedCoupon.id,
            token as string
          )
        : await couponResources.create(parsedPayload, token as string);
    } catch (e) {
      console.error("Error-handleOnSubmitCoupon: ", e);
      window.alert("Ocurrió un error al crear/editar el cupón.");
    } finally {
      setFormType("");
      setSelectedCoupon({} as ICoupon);
      handleOnRetrieveCoupons();
      setLoadingModalVisible(false);
    }
  };

  const handleOnDeleteCoupon = async (couponId: string): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await couponResources.delete(couponId, token as string);
      if (coupons.length === 1) setCoupons([]);
    } catch (e) {
      console.error("Error-handleOnDeleteCoupon: ", e);
      window.alert("Ocurrió un error al eliminar el cupón.");
    } finally {
      handleOnRetrieveCoupons();
      setLoadingModalVisible(false);
    }
  };

  const getPreviousValues = (): CouponPayloadType => {
    let previousValues: CouponPayloadType = {} as CouponPayloadType;
    if (!isEmpty(selectedCoupon)) {
      const { created, id, updated, ...prev } = selectedCoupon;
      const existingDiscount = !isNil(prev.discountInPesos)
        ? prev.discountInPesos
        : prev.discountPercentage;
      previousValues = {
        ...prev,
        beginDate: new Date(
          (selectedCoupon.beginDate as CustomTimestamp)._seconds * 1000
        ),
        endDate: new Date(
          (selectedCoupon.endDate as CustomTimestamp)._seconds * 1000
        ),
        discountInPesos: existingDiscount,
        discountPercentage: existingDiscount,
      };
    }
    return previousValues;
  };

  const columns: ITypedListTableColumn<ICoupon>[] = [
    {
      accessorKey: "code",
      header: "Código",
      cell: ({ row }) => <Text>{row.original.code}</Text>,
    },
    {
      header: "Validez",
      cell: ({ row }) => (
        <Text>
          {`${format(
            new Date(
              (row.original.beginDate as CustomTimestamp)._seconds * 1000
            ),
            "MMM dd, yyyy"
          )} - ${format(
            new Date((row.original.endDate as CustomTimestamp)._seconds * 1000),
            "MMM dd, yyyy"
          )}`}
        </Text>
      ),
    },
    {
      header: "Descuento",
      cell: ({ row }) => (
        <Text>
          {!isNil(row.original.discountInPesos)
            ? `$${row.original.discountInPesos?.toFixed(2)}`
            : `${row.original.discountPercentage}%`}
        </Text>
      ),
    },
    {
      header: "Usos",
      cell: ({ row }) => (
        <Text>
          {row.original.redeems.used} / {row.original.redeems.total}
        </Text>
      ),
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <HStack>
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            onClick={() => {
              setSelectedCoupon(row.original);
              setFormType("edit");
              onOpen();
            }}
            rounded="full"
            fontSize={12}
          >
            Editar
          </Button>
          <Button
            bg="red"
            color="white"
            fontSize={12}
            onClick={() => handleOnDeleteCoupon(row.original.id)}
            rounded="full"
          >
            Eliminar
          </Button>
        </HStack>
      ),
    },
  ];

  const getCouponDetails = (coupon: ICoupon) => {
    return [
      {
        label: "Validez",
        value: `${format(
          new Date((coupon.beginDate as CustomTimestamp)._seconds * 1000),
          "MMM dd, yyyy"
        )} - ${format(
          new Date((coupon.endDate as CustomTimestamp)._seconds * 1000),
          "MMM dd, yyyy"
        )}`,
      },
      {
        label: "Descuento",
        value: !isNil(coupon.discountInPesos)
          ? `$${coupon.discountInPesos?.toFixed(2)}`
          : `${coupon.discountPercentage}%`,
      },
      {
        label: "Usos",
        value: `${coupon.redeems.used}/${coupon.redeems.total}`,
      },
    ];
  };

  useEffect(() => {
    handleOnRetrieveCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      pageHeader="Cupones"
      loadingModalVisible={loadingModalVisible}
    >
      <ZubeModal
        isOpen={isOpen}
        onClose={() => {
          setSelectedCoupon({} as ICoupon);
          onClose();
        }}
        title={formType === "create" ? "Crear Cupón" : "Editar Cupón"}
      >
        <CouponForm
          handleOnSubmit={handleOnSubmitCoupon}
          initialDiscountType={
            !isNil(selectedCoupon.discountPercentage) ? "percentage" : "mxn"
          }
          onScreenChange={() => {
            setSelectedCoupon({} as ICoupon);
            onClose();
          }}
          previousValues={getPreviousValues()}
        />
      </ZubeModal>
      <HStack justifyContent="flex-end" w="95%">
        <Button
          bg={zubeColors.zubeOrange.light}
          borderRadius="full"
          color="white"
          mt={5}
          onClick={() => {
            setFormType("create");
            onOpen();
          }}
        >
          Nuevo Cupón
        </Button>
      </HStack>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {coupons.map((coupon, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={coupon.code}
                  rightElement={
                    <HStack>
                      <Button
                        color="white"
                        bg={zubeColors.zubeGreen}
                        onClick={() => {
                          setSelectedCoupon(coupon);
                          setFormType("edit");
                          onOpen();
                        }}
                        rounded="full"
                        fontSize={12}
                      >
                        Editar
                      </Button>
                      <Button
                        bg="red"
                        color="white"
                        fontSize={12}
                        onClick={() => handleOnDeleteCoupon(coupon.id)}
                        rounded="full"
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  }
                >
                  <VStack w="100%">
                    {getCouponDetails(coupon).map((item) => (
                      <>
                        <Divider />
                        <HStack fontSize={12} spacing="auto" my={3} w="90%">
                          <Text fontWeight="bold" w="35%">
                            {item.label}
                          </Text>
                          <Text>{item.value}</Text>
                        </HStack>
                      </>
                    ))}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="90%" alignSelf="center">
          <Center bg="white" w="90%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={coupons}
                columns={columns as ColumnDef<object>[]}
                w="90%"
                h="90%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
